var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "abnormalTerminalLog", staticClass: "abnormal-terminal-log" },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area analysisForm" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: {
                model: _vm.form,
                "label-position": "left",
                "label-width": "82px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属企业：" } },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: false, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择车辆：" } },
                [
                  _c("car-tree", {
                    ref: "carSearchTree",
                    attrs: { isNeedSystem: false },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "判断时间：", prop: "time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      align: "right",
                      cellClassName: "timeRangePicker",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "timestamp",
                      "picker-options": _vm.pickerOptions,
                      clearable: false
                    },
                    model: {
                      value: _vm.form.time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSearch()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _vm.$store.state.menu.nowMenuList.indexOf("配置") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", loading: _vm.configLoading },
                          on: {
                            click: function($event) {
                              return _vm.onConfig()
                            }
                          }
                        },
                        [_vm._v("配置")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属企业",
              width: "250",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "cph", label: "车牌号", "show-overflow-tooltip": "" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceNo",
              label: "终端号",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "judgeBeginTime",
              label: "判定开始时间",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "judgeEndTime",
              label: "判定结束时间",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small", sort: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.onViewTour(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "判定配置",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "40%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "dialog-form",
                  attrs: {
                    model: _vm.addForm,
                    "label-width": "120px",
                    size: "small",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小于秒数：", prop: "lessSecond" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.lessSecond,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "lessSecond", $$v)
                          },
                          expression: "addForm.lessSecond"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "大于秒数：", prop: "thanSecond" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.thanSecond,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "thanSecond", $$v)
                          },
                          expression: "addForm.thanSecond"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "异常数量：", prop: "errorCount" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.errorCount,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "errorCount", $$v)
                          },
                          expression: "addForm.errorCount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSave()
                        }
                      }
                    },
                    [_vm._v("确认")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.detailDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "详情",
                visible: _vm.detailDialog,
                "close-on-click-modal": false,
                width: "60%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.detailDialog = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "rowData",
                  staticClass:
                    "demo-form-inline dialog-form dialog-form-inline",
                  staticStyle: { "padding-bottom": "0" },
                  attrs: {
                    model: _vm.rowData,
                    inline: true,
                    "label-width": "140px",
                    "label-position": "left",
                    disabled: ""
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号：", prop: "cph" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.cph,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "cph",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.cph"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属企业：", prop: "companyName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.companyName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "companyName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.companyName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "终端号：", prop: "deviceNo" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.deviceNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "deviceNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.deviceNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "判定开始时间：", prop: "judgeBeginTime" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.judgeBeginTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "judgeBeginTime",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.judgeBeginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "判定结束时间：", prop: "judgeEndTime" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.judgeEndTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "judgeEndTime",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.judgeEndTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "平均时间差（秒）：",
                        prop: "offsetSecond"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.offsetSecond,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "offsetSecond",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.offsetSecond"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "判定次数：", prop: "judgeCount" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.judgeCount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "judgeCount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.judgeCount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "记录时间：", prop: "recordDate" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.rowData.recordDate,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowData,
                              "recordDate",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowData.recordDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.detailDialog = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }