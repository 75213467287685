<template>
  <!-- 异常终端日志 -->
  <div class="abnormal-terminal-log" ref="abnormalTerminalLog">
    <div class="form-area analysisForm" ref="form">
      <el-form
        :model="form"
        ref="form"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="所属企业：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="false"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label="选择车辆：">
          <car-tree
            ref="carSearchTree"
            :isNeedSystem="false"
            @getData="getVehIds"
          ></car-tree>
        </el-form-item>
        <el-form-item label="判断时间：" prop="time">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            align="right"
            cellClassName="timeRangePicker"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :picker-options="pickerOptions"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
          <el-button
            size="small"
            :loading="configLoading"
            v-if="$store.state.menu.nowMenuList.indexOf('配置') >= 0"
            @click="onConfig()"
            >配置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        width="250"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="deviceNo"
        label="终端号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="judgeBeginTime"
        label="判定开始时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="judgeEndTime"
        label="判定结束时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            @click="onViewTour(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <!-- 全局判定配置弹窗 -->
    <el-dialog
      title="判定配置"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="120px"
        size="small"
        :rules="rules"
        class="dialog-form"
      >
        <el-form-item label="小于秒数：" prop="lessSecond">
          <el-input
            type="number"
            placeholder="请输入"
            v-model="addForm.lessSecond"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="大于秒数：" prop="thanSecond">
          <el-input
            type="number"
            placeholder="请输入"
            v-model="addForm.thanSecond"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="异常数量：" prop="errorCount">
          <el-input
            type="number"
            placeholder="请输入"
            v-model="addForm.errorCount"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      title="详情"
      v-if="detailDialog"
      :visible.sync="detailDialog"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form
        :model="rowData"
        :inline="true"
        ref="rowData"
        label-width="140px"
        label-position="left"
        class="demo-form-inline dialog-form dialog-form-inline"
        style="padding-bottom: 0"
        disabled
      >
        <el-form-item label="车牌号：" prop="cph">
          <el-input v-model.trim="rowData.cph"></el-input>
        </el-form-item>
        <el-form-item label="所属企业：" prop="companyName">
          <el-input v-model.trim="rowData.companyName"></el-input>
        </el-form-item>
        <el-form-item label="终端号：" prop="deviceNo">
          <el-input v-model.trim="rowData.deviceNo"></el-input>
        </el-form-item>
        <el-form-item label="判定开始时间：" prop="judgeBeginTime">
          <el-input v-model.trim="rowData.judgeBeginTime"></el-input>
        </el-form-item>
        <el-form-item label="判定结束时间：" prop="judgeEndTime">
          <el-input v-model.trim="rowData.judgeEndTime"></el-input>
        </el-form-item>
        <el-form-item label="平均时间差（秒）：" prop="offsetSecond">
          <el-input v-model.trim="rowData.offsetSecond"></el-input>
        </el-form-item>
        <el-form-item label="判定次数：" prop="judgeCount">
          <el-input v-model.trim="rowData.judgeCount"></el-input>
        </el-form-item>
        <el-form-item label="记录时间：" prop="recordDate">
          <el-input v-model.trim="rowData.recordDate"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="detailDialog = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue';
import carTree from "@/components/carTree/carSearchTree.vue";
import {
  getRangeDay,
  formatDay,
} from "@/common/utils/index";
import {
  queryAbnormalDeviceLogPage,
  queryAbnormalDeviceLogConfig,
  saveAbnormalDeviceLogConfig
} from '@/api/lib/api.js';
export default {
  components: {
    companySelectForSearch,
    carTree
  },
  data () {
    return {
      form: {
        companyId: null,
        vehicleNoList: [],
        time: getRangeDay(true),
        currentPage: 1,
        pageSize: 10,
      },
      searchLoading: false,
      configLoading: false,
      tableData: [],
      tableHeight: 0,
      total: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay(this.chooseToday)[1] - 3600 * 1000 * 24 * 7;
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30;
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dialogVisible: false,
      addForm: {
        errorCount: 0,
        lessSecond: 0,
        thanSecond: 0
      },
      editLoading: false,
      rules: {
        lessSecond: [{ required: true, message: "请输入", trigger: "blur" }],
        thanSecond: [{ required: true, message: "请输入", trigger: "blur" }],
        errorCount: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      detailDialog: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      window.addEventListener('resize', this.computeHeight, false);
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  },
  methods: {
    //详情
    onViewTour (row) {
      this.detailDialog = true;
      this.rowData = row;
    },
    //配置
    onConfig () {
      queryAbnormalDeviceLogConfig().then(res => {
        if (res.code === 1000) {
          this.addForm = res.data;
        }
      });
      this.$nextTick(() => {
        this.dialogVisible = true;
      })
    },
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          saveAbnormalDeviceLogConfig(this.addForm).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg);
              this.getListByField();
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg);
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          })
        }
      })
    },
    getListByField () {
      let data = { ...this.form };
      data.beginTime = formatDay(this.form.time[0]);
      data.endTime = formatDay(this.form.time[1]);
      delete data.time;
      queryAbnormalDeviceLogPage(data).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list || [];
          this.total = res.data.total;
          if (res.data.list.length === 0 && data.currentPage !== 1) {
            this.form.currentPage -= 1;
            this.getListByField();
          }
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg);
        }
        this.searchLoading = false;
      }).catch(err => {
        this.searchLoading = false;
      })
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.abnormalTerminalLog.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        formHeight -
        16 -
        paginationHeight -
        16;
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val.length === 0 ? null : val[0];
    },
    // 获取车辆数据
    getVehIds (val) {
      // this.$refs.form.clearValidate();
      this.form.vehicleNoList = [...val];
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    // 当前页改变
    handleCurrentChange (page) {
      this.form.currentPage = page;
      this.getListByField()
    },
    // 每页显示条数改变
    handleSizeChange (size) {
      this.form.currentPage = 1;
      this.form.pageSize = size;
      this.getListByField();
    },
  }
}
</script>
<style lang="scss" scoped>
.abnormal-terminal-log {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>